export const LEVEL_UP_TIME = 2000;
export const GRADIENT_LEVELS = 120;

export const getLevelVariable = (level: number) => {
  if (level < 5) {
    return 'var(--lvl-1-4)';
  }

  if (level < 20) {
    const min = parseInt(`${level / 5}`) * 5;
    const max = min + 4;

    return `var(--lvl-${min}-${max})`;
  }

  if (level < 200) {
    const min = parseInt(`${level / 10}`) * 10;
    const max = min + 9;

    return `var(--lvl-${min}-${max})`;
  }

  return 'var(--lvl-200)';
};
